@use '@picocss/pico/scss/pico' with (
  $theme-color: "jade"
);

// Jetbrains Mono for Code Snippets

:root {
  --pico-font-family-emoji: "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --pico-font-family-sans-serif: system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, Helvetica, Arial, "Helvetica Neue", sans-serif, var(--pico-font-family-emoji);
  --pico-font-family-monospace: 'JetBrains Mono', ui-monospace, SFMono-Regular, "SF Mono", Menlo, Consolas, "Liberation Mono", monospace, var(--pico-font-family-emoji);
  --pico-font-family: var(--pico-font-family-sans-serif);
}

.navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navigation > img {
  width: 3.5rem;
  height: auto;
  padding-right: 0.5rem;
}

.navigation a {
  --pico-text-decoration: unset;
  font-size: 1.5rem;
}

ul.unstyled li {
  list-style: none;
}

ul.unstyled li span[class="material-icons"] {
  padding-right: 1rem;
}
